@font-face {
    font-family: 'Manrope';
    src: url('./assets/fonts/Manrope-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','poppins','Product Sans'
        sans-serif; */
    font-family: 'Manrope', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: auto;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.mybzb_text_clr {
    color: #AC3533;
}

.mybzb_bg_clr {
    background-color: #AC3533;
}

.mybzb_bg_pinkclr {
    background-color: #FDEDF1;
}

.color-pick {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 100%;
}

.color-pick input[type='color'] {
    width: 100%;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000001A;
    transform: scale(1.5);
}

.inactive_bg {
    background-color: rgb(255 0 0 / 10%);
}

.edit_bg {
    background-color: rgb(126 121 121 / 10%);
}

.selective_item {
    color: #FFFFFF !important;
}

.border_clr {
    border: 1px solid #AC3533 !important;
}

.mybzb_bg_pinkclr {
    background-color: #FDEDF1;
}

.mybzb_text_clr {
    color: #AC3533;
}

table tbody {
    background-color: #FFFFFF;
}

.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #AC3533;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 1025px) {
    .hide-at-1021px {
      display: none;
    }
  }

  @media (min-width: 1026px) {
    .hide-logo-at-larger-screens {
      display: none;
    }
  }

.read-only-input {
    pointer-events: none; /* Disables all interactions */
    cursor: default; /* Sets the cursor to default */
}